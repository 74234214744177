<template>
<div>
  secretUserManage
</div>
</template>

<script>
export default {
  name: 'secretUserManage'

}
</script>

<style lang='scss' scoped>

</style>
